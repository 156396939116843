import React from 'react';
import * as R from 'ramda';
import { format } from 'date-fns';
import { useQuery, gql } from '@apollo/client';
import { StaffReportMetricType } from '@poly/constants';
import { useParams } from '@poly/client-routing';
import { paginationToQueryParams } from '@poly/client-utils/src/pagination.js';
import { ensureIsDate } from '@poly/utils/src/dates.js';
import { useMapConfigToTableProps } from '@poly/admin-ui/src/hooks/useMapConfigToTableProps.js';
import { usePagination } from '@poly/admin-ui/src/redux/pagination.js';
import { useSetInitialPaginationOnMount } from '@poly/admin-ui/src/hooks/useSetInitialPaginationOnMount.js';
import { useSetItemsCount } from '@poly/admin-ui/src/hooks/useSetItemsCount.js';

import {
  UpdateDocumentLink,
  EntityReferenceLinkTabs,
  UpdateDocumentFragmentForLink,
} from '../../components/UpdateDocumentLink.js';

import { SupplierLink } from '../../components/Links.js';
import { ClientLink } from '../../sidebars/ClientSidebar/useOpenClientSidebar.js';
import { ProjectLink } from '../../sidebars/ProjectSidebar/useOpenProjectSidebar.js';
import { PropertyLink } from '../../sidebars/PropertySidebar/useOpenPropertySidebar.js';

export const staffReportDetailsQuery = gql`
  fragment CommonProjectDetailsFields on Project {
    _id
    projectId
    type
    client {
      _id
      name
    }
    property {
      _id
      name
    }
  }

  query STAFF_ACTIVITY_DETAILS($input: StaffReportDetailsInput!, $userId: ID) {
    user(id: $userId) {
      _id
      profile {
        fullName
      }
    }
    staffReportDetails(input: $input) {
      createdSuppliers {
        hits {
          _id
          company {
            name
          }
          createdAt
        }
        total
      }
      postedUpdates {
        hits {
          _id
          documentId
          createdAt
          document {
            ...UpdateDocumentFragmentForLink
          }
        }
        total
      }
      completedProjectTasks {
        hits {
          _id
          completedAt
          description
          document {
            project {
              type
              projectId
            }
          }
        }
        total
      }
      createdProjects {
        hits {
          createdAt
          ...CommonProjectDetailsFields
        }
        total
      }
      completedProjects {
        hits {
          workCompletionDate
          ...CommonProjectDetailsFields
        }
        total
      }
      activeProjects {
        hits {
          createdAt
          ...CommonProjectDetailsFields
        }
        total
      }
      activeChildrenProjects {
        hits {
          createdAt
          ...CommonProjectDetailsFields
        }
        total
      }
      activeRecurringProjects {
        hits {
          _id
          projectId
          childType
          client {
            _id
            name
          }
          property {
            _id
            name
          }
          createdAt
        }
        total
      }
    }
  }
  ${UpdateDocumentFragmentForLink}
`;

// eslint-disable-next-line @cspell/spellchecker
const customDateFormat = 'EEEE MM/dd/yyyy hh:mmaaa OOOO';

const customFormatter = (date) => format(date, customDateFormat);

// customFormatDate :: String -> Object -> String
const customFormatDate = (prop) =>
  R.compose(R.when(R.is(Date), customFormatter), ensureIsDate, R.prop(prop));

const createdSuppliersTableConfig = [
  ['Date', customFormatDate('createdAt')],
  [
    'Supplier',
    (supplier) => (
      <SupplierLink _id={supplier._id} name={supplier.company.name} />
    ),
  ],
];

const postedUpdatesTableConfig = [
  ['Date', customFormatDate('createdAt')],
  [
    'Document',
    ({ _id, document, documentId }) => (
      <UpdateDocumentLink
        document={document}
        documentId={documentId}
        documentUpdateId={_id}
        tab={EntityReferenceLinkTabs.UPDATES}
        target="_blank"
      />
    ),
  ],
];

const completedProjectTasksTableConfig = [
  ['Completion Date', customFormatDate('completedAt')],
  [
    'Project',
    ({ document: { project } }) => <ProjectLink inNewTab {...project} />,
  ],
  ['Name', R.prop('description')],
];

const commonProjectTableColumns = [
  ['Project', (project) => <ProjectLink inNewTab {...project} />],
  ['Property', ({ property }) => <PropertyLink inNewTab {...property} />],
  ['Client', ({ client }) => <ClientLink inNewTab {...client} />],
];

const createdProjectsTableConfig = [
  ['Date', customFormatDate('createdAt')],
  ...commonProjectTableColumns,
];

const completedProjectsTableConfig = [
  ['Date', customFormatDate('workCompletionDate')],
  ...commonProjectTableColumns,
];

const metricTypeToTableConfigMap = {
  [StaffReportMetricType.CREATED_SUPPLIERS]: createdSuppliersTableConfig,
  [StaffReportMetricType.POSTED_UPDATES]: postedUpdatesTableConfig,
  [StaffReportMetricType.COMPLETED_PROJECT_TASKS]:
    completedProjectTasksTableConfig,
  [StaffReportMetricType.CREATED_PROJECTS]: createdProjectsTableConfig,
  [StaffReportMetricType.COMPLETED_PROJECTS]: completedProjectsTableConfig,
};

// getTableConfigByMetricType :: String -> [Pair String Function]
const getTableConfigByMetricType = R.compose(
  R.defaultTo(createdProjectsTableConfig),
  R.prop(R.__, metricTypeToTableConfigMap),
);

export const useStaffReportDetail = () => {
  useSetInitialPaginationOnMount();
  const { userId, type, startDate, endDate } = useParams();

  const { pagination } = usePagination();

  const { data, loading } = useQuery(staffReportDetailsQuery, {
    variables: {
      userId,
      input: {
        startDate,
        endDate,
        metricType: type,
        userId,
        ...paginationToQueryParams(pagination),
      },
    },
    fetchPolicy: 'network-only',
  });

  const activityRecords = R.pathOr(
    [],
    ['staffReportDetails', type, 'hits'],
    data,
  );

  const { rows, headers, columns, sortQueries } = useMapConfigToTableProps(
    R.identity,
    getTableConfigByMetricType(type),
    activityRecords,
  );

  useSetItemsCount(R.pathOr([], ['staffReportDetails', type, 'total']), data);

  return {
    rows,
    headers,
    columns,
    sortQueries,
    loading,
    data,
    type,
    startDate,
    endDate,
    activityRecords,
  };
};
