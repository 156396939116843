import React from 'react';
import * as R from 'ramda';
import { SidebarRow } from '@poly/admin-ui';
import { shape, bool } from 'prop-types';
import { pathOrNothingUI } from '@poly/client-utils';
import { pathEqLegacy } from '@poly/utils';

import { getProjectFeeTypeByPath } from '../components/commonSidebarUtils.js';
import { getToggleSettingSectionText } from './helpers.js';
import { getUserFriendlyProjectTypeName } from '../ProjectSidebar/projectSidebarUtils.js';
import {
  SectionLabel,
  SectionWrapper,
  BlockWithLabel,
  threeBlocksProps,
  threeBlocksWrapperProps,
} from '../components/commonSidebarComponents.js';
import {
  showAssetScannerEnabledInfo,
  showClientPortalEnabledInfo,
} from './tabs/clientSidebarTabsUtils.js';
import {
  getSectionText,
  getSectionOutsideLink,
} from '../components/commonSidebarSectionFormatters.js';
import {
  isRequestsAppEnabled,
  isClientPortalAppEnabled,
  isProjectFeeTypeSelected,
  isAssetScannerAppEnabled,
} from './forms/form/sections/configsSection.js';
import {
  HelpTooltip,
  ShortExplanationKeys,
} from '../../components/HelpTooltip.js';

// getAllowClientPortalConfig :: String -> Client -> String
const getAllowClientPortalConfig = (key) =>
  R.ifElse(
    pathEqLegacy(['configs', 'clientPortalApp', key], true),
    R.always('On'),
    R.always('Off'),
  );

// getAllowProjectCreation :: Client -> String
const getAllowProjectCreation = getAllowClientPortalConfig(
  'allowProjectCreation',
);

// getContractProp :: String -> Client -> String
const getConfigsLogoProp = (prop) =>
  R.compose(R.prop(prop), R.defaultTo({}), R.path(['configs', 'logoImage']));

// getClientBranding :: Client -> String
const getClientBranding = R.compose(
  R.toUpper,
  pathOrNothingUI(['configs', 'requestsApp', 'branding']),
);

// getClientRequestProjectType :: Client -> String
const getClientRequestProjectType = R.compose(
  getUserFriendlyProjectTypeName,
  R.path(['configs', 'requestsApp', 'projectType']),
);

// getShowPMsAndAssets :: Client -> String
const getShowPMsAndAssets = getAllowClientPortalConfig('showPMsAndAssets');

// getProjectCreationConfig :: String -> Client -> Boolean
const getProjectCreationConfig = (appKey) =>
  R.path(['configs', appKey, 'allowProjectCreation']);

// prepareSettingsInfo :: Client -> SidebarSettingsInfo
// SidebarSettingsInfo = {
//    enableGlCodes: Boolean
//    enableCostCenter: Boolean
//    isUMCAccountFormat: Boolean
//    enableChildAccounts: Boolean
//    enableReportOnlyProjectType: Boolean
//    enablePropertyLocationHierarchy: Boolean
//    clientBranding: String
//    showPMsAndAssets: Boolean
//    enablePurchaseOrder: Boolean
//    enabledClientPortal: Boolean
//    enabledAssetScanner: Boolean
//    requiresSignOffSheet: Boolean
//    allowProjectCreation: Boolean
//    clientRequestProjectType: String
//    enabledReportOnlyClientInvoiceCreation: Boolean
// }
const prepareSettingsInfo = R.compose(
  R.mergeAll,
  R.juxt([
    R.pick([
      'enableGlCodes',
      'enableCostCenter',
      'isUMCAccountFormat',
      'enableChildAccounts',
      'enableReportOnlyProjectType',
      'enablePropertyLocationHierarchy',
    ]),
    R.applySpec({
      allowAdminProjectCreation: getProjectCreationConfig('adminApp'),
      allowAssetScannerProjectCreation:
        getProjectCreationConfig('assetScannerApp'),
      clientBranding: getClientBranding,
      showPMsAndAssets: getShowPMsAndAssets,
      enablePurchaseOrder: R.path(['configs', 'enablePurchaseOrder']),
      isPORequiredForProject: R.path(['configs', 'isPORequiredForProject']),
      enabledClientPortal: showClientPortalEnabledInfo,
      enabledAssetScanner: showAssetScannerEnabledInfo,
      requiresSignOffSheet: R.path(['configs', 'requiresSignOffSheet']),
      allowProjectCreation: getAllowProjectCreation,
      enableRequesterSurvey: R.path(['configs', 'requesterSurvey', 'enabled']),
      clientRequestProjectType: getClientRequestProjectType,
      allowReplacementCost: R.path([
        'configs',
        'assetScannerApp',
        'allowReplacementCost',
      ]),
      enabledReportOnlyClientInvoiceCreation: R.path([
        'configs',
        'clientInvoicing',
        'reportOnlyClientInvoiceCreation',
      ]),
    }),
  ]),
);

export function ClientSidebarSettingsInfo({ client }) {
  const {
    enableGlCodes,
    clientBranding,
    showPMsAndAssets,
    enableCostCenter,
    isUMCAccountFormat,
    enabledAssetScanner,
    enabledClientPortal,
    enableChildAccounts,
    enablePurchaseOrder,
    allowReplacementCost,
    requiresSignOffSheet,
    allowProjectCreation,
    enableRequesterSurvey,
    clientRequestProjectType,
    enableReportOnlyProjectType,
    enablePropertyLocationHierarchy,
    enabledReportOnlyClientInvoiceCreation,
    allowAdminProjectCreation,
    allowAssetScannerProjectCreation,
    isPORequiredForProject,
  } = prepareSettingsInfo(client);

  return (
    <SectionWrapper>
      <SidebarRow>
        <SectionLabel>Settings</SectionLabel>
      </SidebarRow>
      <SidebarRow>
        <SectionLabel size="14px">Client Settings</SectionLabel>
      </SidebarRow>
      <SidebarRow {...threeBlocksWrapperProps}>
        <BlockWithLabel
          justify
          id="enableChildAccounts"
          label="Enable Child Accounts"
          ActionBtn={
            <HelpTooltip
              position="top"
              shortExplanation={ShortExplanationKeys.enableChildAccounts}
            />
          }
          {...threeBlocksProps}
          Component={getToggleSettingSectionText(enableChildAccounts)}
        />
        <BlockWithLabel
          justify
          id="isUMCAccountFormat"
          label="UMC Account Format"
          ActionBtn={
            <HelpTooltip
              position="top"
              shortExplanation={ShortExplanationKeys.isUMCAccountFormat}
            />
          }
          {...threeBlocksProps}
          Component={getToggleSettingSectionText(isUMCAccountFormat)}
        />
        <BlockWithLabel
          justify
          id="requiresSignOffSheet"
          label="Requires Sign Off Sheet"
          ActionBtn={
            <HelpTooltip
              position="left"
              shortExplanation={ShortExplanationKeys.requiresSignOffSheet}
            />
          }
          {...threeBlocksProps}
          Component={getToggleSettingSectionText(requiresSignOffSheet)}
        />
        <BlockWithLabel
          justify
          id="portal"
          label="Portal"
          {...threeBlocksProps}
          Component={getToggleSettingSectionText(enabledClientPortal)}
        />
        <BlockWithLabel
          justify
          id="enableAssetScanner"
          label="Enable Asset Scanner"
          {...threeBlocksProps}
          Component={getToggleSettingSectionText(enabledAssetScanner)}
        />
        <BlockWithLabel
          justify
          id="reportOnly"
          label="Report Only"
          ActionBtn={
            <HelpTooltip
              position="left"
              shortExplanation={
                ShortExplanationKeys.enableReportOnlyProjectType
              }
            />
          }
          {...threeBlocksProps}
          Component={getToggleSettingSectionText(enableReportOnlyProjectType)}
        />
        <BlockWithLabel
          justify
          id="reportOnlyClientInvoiceCreation"
          label="Client Invoice Creation - Report Only"
          ActionBtn={
            <HelpTooltip
              position="top"
              shortExplanation={
                ShortExplanationKeys.reportOnlyClientInvoiceCreation
              }
            />
          }
          {...threeBlocksProps}
          Component={getToggleSettingSectionText(
            enabledReportOnlyClientInvoiceCreation,
          )}
        />
        <BlockWithLabel
          justify
          id="enablePropertyLocationHierarchy"
          label="Enable Property Location Hierarchy"
          ActionBtn={
            <HelpTooltip
              position="top"
              shortExplanation={
                ShortExplanationKeys.enablePropertyLocationHierarchy
              }
            />
          }
          {...threeBlocksProps}
          Component={getToggleSettingSectionText(
            enablePropertyLocationHierarchy,
          )}
        />
        <BlockWithLabel
          justify
          id="enableRequesterSurvey"
          label="Requester Survey"
          ActionBtn={
            <HelpTooltip
              position="left"
              shortExplanation={ShortExplanationKeys.requesterSurveyEnabled}
            />
          }
          {...threeBlocksProps}
          Component={getToggleSettingSectionText(enableRequesterSurvey)}
        />
        <BlockWithLabel
          justify
          id="allowAdminProjectCreation"
          label="Allow project creation on the Admin"
          ActionBtn={
            <HelpTooltip
              position="top"
              shortExplanation={ShortExplanationKeys.allowAdminProjectCreation}
            />
          }
          {...threeBlocksProps}
          Component={getToggleSettingSectionText(allowAdminProjectCreation)}
        />
      </SidebarRow>
      <SidebarRow>
        <SectionLabel size="14px">Financial Settings</SectionLabel>
      </SidebarRow>
      <SidebarRow {...threeBlocksWrapperProps}>
        <BlockWithLabel
          justify
          id="enableGlCodes"
          label="Enable GL Codes"
          ActionBtn={
            <HelpTooltip
              position="top"
              shortExplanation={ShortExplanationKeys.enableGlCodes}
            />
          }
          {...threeBlocksProps}
          Component={getToggleSettingSectionText(enableGlCodes)}
        />
        <BlockWithLabel
          justify
          id="enableCostCenter"
          label="Enable Cost Center"
          ActionBtn={
            <HelpTooltip
              position="top"
              shortExplanation={ShortExplanationKeys.enableCostCenter}
            />
          }
          {...threeBlocksProps}
          Component={getToggleSettingSectionText(enableCostCenter)}
        />
        <BlockWithLabel
          justify
          id="enablePOs"
          label="Enable POs"
          ActionBtn={
            <HelpTooltip
              position="left"
              shortExplanation={ShortExplanationKeys.enablePOs}
            />
          }
          {...threeBlocksProps}
          Component={getToggleSettingSectionText(enablePurchaseOrder)}
        />
      </SidebarRow>
      {enablePurchaseOrder && (
        <SidebarRow {...threeBlocksWrapperProps}>
          <BlockWithLabel
            justify
            id="isPORequiredForProject"
            label="PO Required To Close Project"
            ActionBtn={
              <HelpTooltip
                position="top"
                shortExplanation={ShortExplanationKeys.isPORequiredForProject}
              />
            }
            {...threeBlocksProps}
            Component={getToggleSettingSectionText(isPORequiredForProject)}
          />
        </SidebarRow>
      )}

      {isClientPortalAppEnabled(client) && (
        <>
          <SidebarRow>
            <SectionLabel size="14px">Client Portal Configs</SectionLabel>
          </SidebarRow>
          <SidebarRow {...threeBlocksWrapperProps}>
            <BlockWithLabel
              justify
              id="allow-project-creation"
              label="Allow Project Creation"
              ActionBtn={
                <HelpTooltip
                  position="top"
                  shortExplanation={ShortExplanationKeys.allowProjectCreation}
                />
              }
              {...threeBlocksProps}
              Component={getSectionText(allowProjectCreation)}
            />
            <BlockWithLabel
              justify
              id="show-pms-and-assets"
              label="Show PMs and Assets"
              ActionBtn={
                <HelpTooltip
                  position="top"
                  shortExplanation={ShortExplanationKeys.showPMsAndAssets}
                />
              }
              {...threeBlocksProps}
              Component={getSectionText(showPMsAndAssets)}
            />
          </SidebarRow>
        </>
      )}
      {isRequestsAppEnabled(client) && (
        <>
          <SidebarRow>
            <SectionLabel size="14px">Request Configs</SectionLabel>
          </SidebarRow>
          <SidebarRow {...threeBlocksWrapperProps}>
            <BlockWithLabel
              id="configs-branding"
              label="Branding"
              {...threeBlocksProps}
              Component={getSectionText(clientBranding)}
            />
            <BlockWithLabel
              id="configs-logo"
              label="Client Logo"
              {...threeBlocksProps}
              Component={getSectionOutsideLink(
                getConfigsLogoProp('url')(client),
                getConfigsLogoProp('fileName')(client),
              )}
            />
            <BlockWithLabel
              id="configs-cost-type"
              label="Cost type"
              {...threeBlocksProps}
              Component={getSectionText(clientRequestProjectType)}
            />
            {isProjectFeeTypeSelected(client) ? (
              <BlockWithLabel
                id="configs-service-type"
                label="Service Type"
                {...threeBlocksProps}
                Component={getSectionText(
                  getProjectFeeTypeByPath([
                    'configs',
                    'requestsApp',
                    'projectFeeType',
                  ])(client),
                )}
              />
            ) : (
              <BlockWithLabel
                id="configs-service-type"
                label="Service Type"
                {...threeBlocksProps}
                Component={getSectionText(
                  pathOrNothingUI(
                    ['configs', 'requestsApp', 'projectServiceType', 'name'],
                    client,
                  ),
                )}
              />
            )}
          </SidebarRow>
        </>
      )}
      {isAssetScannerAppEnabled(client) && (
        <>
          <SidebarRow>
            <SectionLabel size="14px">Asset Scanner Configs</SectionLabel>
          </SidebarRow>
          <SidebarRow {...threeBlocksWrapperProps}>
            <BlockWithLabel
              id="configs-replacement-cost"
              label="Allow viewing Asset Replacement Cost"
              Component={getToggleSettingSectionText(allowReplacementCost)}
            />
            <BlockWithLabel
              justify
              id="allowAssetScannerProjectCreation"
              label="Allow project creation on the Asset Scanner"
              ActionBtn={
                <HelpTooltip
                  position="top"
                  shortExplanation={
                    ShortExplanationKeys.allowAssetScannerProjectCreation
                  }
                />
              }
              {...threeBlocksProps}
              Component={getToggleSettingSectionText(
                allowAssetScannerProjectCreation,
              )}
            />
          </SidebarRow>
        </>
      )}
    </SectionWrapper>
  );
}

ClientSidebarSettingsInfo.propTypes = {
  client: shape({
    enablePurchaseOrder: bool,
    isUMCAccountFormat: bool,
  }),
};
