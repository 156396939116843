import React from 'react';
import * as R from 'ramda';
import { useModalContext, defaultLoginAppOptions } from '@poly/admin-ui';
import {
  CLIENT_PORTAL_APP_NAME,
  ASSET_SCANNER_APP_NAME,
  REQUESTS_APP_NAME,
} from '@poly/security';

import { ItemsSelect } from '../../../../../modules/forms/fields/itemsSelect/index.js';

const availableClientAppOptions = R.compose(
  R.filter(
    R.propSatisfies(
      R.includes(R.__, [
        REQUESTS_APP_NAME,
        ASSET_SCANNER_APP_NAME,
        CLIENT_PORTAL_APP_NAME,
      ]),
      0,
    ),
  ),
  R.map(R.values),
)(defaultLoginAppOptions);

export function ClientAppsSelect(props) {
  const { openConfirmModal } = useModalContext();

  const confirmModalProps = {
    cancelBtnCaption: 'No',
    btnCaption: 'Yes',
    id: 'confirmRemoveRequestApp',
    content:
      "Are you sure you want to remove this app? All client's properties Web Forms will be disabled",
  };

  const beforeRemoveItem = (item, handleRemove) => {
    if (item === REQUESTS_APP_NAME) {
      openConfirmModal({
        ...confirmModalProps,
        onConfirm: (closeConfirmModal) => () => {
          handleRemove();
          closeConfirmModal();
        },
      });
    } else {
      handleRemove();
    }
  };

  const additionalProps = {
    width: '95%',
    direction: 'up',
    options: availableClientAppOptions,
    beforeRemoveItem,
  };

  return <ItemsSelect additionalProps={additionalProps} {...props} />;
}
