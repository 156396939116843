import React from 'react';
import styled from 'styled-components';
import { Checkbox as CheckboxComponent } from '@poly/admin-book';

const CheckBoxS = styled(CheckboxComponent)`
  padding-top: 6px;
  input {
    top: 6px;
  }
  .state-re-presenter {
    top: 6px;
  }
`;

const DocumentsCheckboxS = styled(CheckBoxS)`
  position: absolute;
  top: 140px;
  left: 100px;
`;

export function SupplierFormCheckbox(props) {
  return <CheckBoxS label="Yes" {...props} />;
}

export function SupplierDocumentsCheckbox(props) {
  return <DocumentsCheckboxS {...props} label="Exempt" />;
}
