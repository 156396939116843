import React from 'react';
import * as R from 'ramda';
import { string, shape } from 'prop-types';
import { capitalizeFirstLetter, pathOrNothingUI } from '@poly/client-utils';
import { SidebarRow, SidebarTabs } from '@poly/admin-ui';
import { isNilOrEmpty } from '@poly/utils';
import { NOTHING_UI_STRING } from '@poly/constants';

import {
  SectionLabel,
  BlockWithLabel,
  threeBlocksProps,
  threeBlocksWrapperProps,
  SectionWrapperWithoutBorder,
} from '../components/commonSidebarComponents.js';
import { PropertyTMRulesTab } from './PropertyTMRulesTab.js';
import { PropertyBidRulesTab } from './PropertyBidRulesTab.js';
import { getSectionText } from '../components/commonSidebarSectionFormatters.js';

// prepareInvoiceToField :: Property -> String
const prepareInvoiceToField = R.compose(
  pathOrNothingUI(['invoiceTo']),
  R.over(
    R.lensProp('invoiceTo'),
    R.when(
      R.complement(isNilOrEmpty),
      R.compose(R.replace('_', ' '), capitalizeFirstLetter),
    ),
  ),
);

// hasCodingConfig :: Property -> Boolean
const hasCodingConfig = R.pathOr(false, [
  'client',
  'configs',
  'propertyFields',
  'financialCoding',
]);

// getPropertyCostCenter :: Property -> String
const getPropertyCostCenter = R.either(
  R.prop('costCenter'),
  R.pathOr(NOTHING_UI_STRING, ['masterProperty', 'costCenter']),
);

// enabledClientCostCenter :: Property -> Boolean
const enabledClientCostCenter = R.pathOr(false, ['client', 'enableCostCenter']);

function PropertySidebarFinancial({ property }) {
  return (
    <SectionWrapperWithoutBorder>
      <SidebarRow>
        <SectionLabel>Financial</SectionLabel>
      </SidebarRow>
      <SidebarRow {...threeBlocksWrapperProps}>
        <BlockWithLabel
          id="invoice-to"
          label="Invoice To"
          {...threeBlocksProps}
          Component={getSectionText(prepareInvoiceToField(property))}
        />
        {hasCodingConfig(property) && (
          <BlockWithLabel
            id="coding"
            label="Coding"
            {...threeBlocksProps}
            Component={getSectionText(
              pathOrNothingUI(['financialCoding'])(property),
            )}
          />
        )}
        {enabledClientCostCenter(property) && (
          <BlockWithLabel
            id="costCenter"
            label="Cost Center"
            {...threeBlocksProps}
            Component={getSectionText(getPropertyCostCenter(property))}
          />
        )}
      </SidebarRow>
    </SectionWrapperWithoutBorder>
  );
}

const propertyFinancialTabs = {
  financial: 'propertyFinancialTab',
  bidRules: 'propertyBidRulesTab',
  tmRules: 'propertyTMRulesTab',
};

export function PropertyFinancialTabs(props) {
  const temporaryHideBidRulesTab = true;

  return (
    <SidebarTabs
      tabs={[
        [
          'Financial',
          propertyFinancialTabs.financial,
          <PropertySidebarFinancial {...props} />,
        ],
        [
          'T&M Rules',
          propertyFinancialTabs.tmRules,
          <PropertyTMRulesTab {...props} />,
        ],
        ...(temporaryHideBidRulesTab
          ? []
          : [
              [
                'Bid Rules',
                propertyFinancialTabs.bidRules,
                <PropertyBidRulesTab {...props} />,
              ],
            ]),
      ]}
      defaultValue={propertyFinancialTabs.financial}
    />
  );
}

PropertySidebarFinancial.propTypes = {
  property: shape({
    invoiceTo: string,
    financialCoding: string,
  }),
};
