import {
  node,
  bool,
  func,
  shape,
  number,
  string,
  object,
  arrayOf,
  oneOfType,
} from 'prop-types';
import * as R from 'ramda';
import styled from 'styled-components';
import React, { useState, useEffect } from 'react';
import ReactSelect, { components as SelectComponents } from 'react-select';
import { BODY_PORTAL_ANCHOR_ID } from '@poly/constants';

import { Icon } from '../Icon/index.js';
import { Checkbox } from '../Checkbox/index.js';
import { defaultTheme } from '../ThemeProvider/index.js';

const { secondaryMid } = defaultTheme.colors;

const HiddenInput = styled.input`
  position: absolute;
  left: 5%;
  height: 0;
  padding: 0;
  margin: 0;
  opacity: 0;
  z-index: -1;
`;

const Wrapper = styled.div`
  position: relative;
  width: ${R.propOr('100%', 'width')};
`;

const WrapperCheckboxService = styled.div`
  display: flex;
  align-items: center;
`;

const getDropdownIndicator = (icon) =>
  function (props) {
    return (
      <SelectComponents.DropdownIndicator {...props}>
        {icon}
      </SelectComponents.DropdownIndicator>
    );
  };

const defaultCheckboxOnChange = () => {
  // no implementation is required
};

function Option({ isSelected, children, innerProps, innerRef }) {
  return (
    <WrapperCheckboxService ref={innerRef} {...innerProps}>
      <Checkbox
        label={children}
        value={isSelected}
        onChange={defaultCheckboxOnChange}
        name={children}
      />
    </WrapperCheckboxService>
  );
}

Option.propTypes = {
  isSelected: bool,
  children: string,
  // eslint-disable-next-line react/forbid-prop-types
  innerProps: object,
  // eslint-disable-next-line react/forbid-prop-types
  innerRef: object,
};

// eslint-disable-next-line import/no-unused-modules
export function BaseSelect({
  placeholder,
  onChange,
  value,
  width,
  onFocus,
  onInputChange,
  onMenuClose,
  setSearchTextForQuery,
  customIcon,
  components,
  styleType,
  isOutForm,
  requiredWithOutError,
  withPortal,
  isClearable,
  ...props
}) {
  const [internalSearchText, setInternalSearchText] = useState('');
  const [savedSearchText, setSavedSearchText] = useState('');
  const [internalValue, setInternalValue] = useState('');

  useEffect(() => {
    if (!R.equals(value, internalValue)) {
      setInternalValue(value);
      setInternalSearchText('');
      setSavedSearchText('');
    }
  }, [value]);

  const onChangeInternal = (option) => {
    onChange(option);
    setInternalSearchText('');
    setSavedSearchText('');
    setInternalValue(option);
  };

  const onFocusInternal = (e) => {
    onFocus(e);
    setInternalSearchText(savedSearchText);
    setSavedSearchText('');
    setSearchTextForQuery(savedSearchText);
  };

  const onMenuCloseInternal = () => {
    onMenuClose();
    setSavedSearchText(internalSearchText);
  };

  const onInputChangeInternal = (text) => {
    onInputChange(text);
    setInternalSearchText(text);
  };

  const DropdownIndicator = getDropdownIndicator(customIcon);

  const preparedComponents = {
    ...(customIcon ? { DropdownIndicator } : {}),
    ...(styleType === 'withCheckBox' ? { Option } : {}),
    ...components,
  };

  const { error, isMulti } = props;

  const requiredErr = isMulti
    ? error && value && !value.length
    : (error && !value) || (error && value && !value.value);

  const getTop = () => {
    if (isOutForm) {
      return 6;
    }
    return 8;
  };

  const portalProps = withPortal
    ? {
        menuPosition: 'fixed',
        menuPortalTarget: document.getElementById(BODY_PORTAL_ANCHOR_ID),
        styles: { menuPortal: (base) => ({ ...base, zIndex: 10002 }) },
      }
    : {};

  const RequiredIconS = styled(Icon)`
    z-index: 2;
    position: absolute;
    right: ${isMulti ? '0' : '5%'};
    top: ${getTop()}px;
  `;

  return (
    <Wrapper width={width}>
      <ReactSelect
        {...props}
        {...portalProps}
        value={internalValue}
        tabSelectsValue={false}
        onFocus={onFocusInternal}
        onChange={onChangeInternal}
        components={preparedComponents}
        inputValue={internalSearchText}
        onMenuClose={onMenuCloseInternal}
        onInputChange={onInputChangeInternal}
        placeholder={savedSearchText || placeholder}
        isClearable={isClearable || !props.required}
        closeMenuOnSelect={
          styleType === 'withCheckBox' ? false : props.closeMenuOnSelect
        }
        hideSelectedOptions={
          styleType === 'withCheckBox' ? false : props.hideSelectedOptions
        }
        disabled
      />
      {!props.isDisabled && (
        <HiddenInput
          tabIndex={-1}
          autoComplete="off"
          value={value}
          required={props.required}
          onChange={() => null}
        />
      )}
      {(requiredErr || (requiredWithOutError && props.hasError && !error)) && (
        <RequiredIconS name="required" color={secondaryMid} />
      )}
    </Wrapper>
  );
}

const propTypesValue = shape({
  value: oneOfType([string, number, bool, object]),
  label: oneOfType([string, number]),
});

BaseSelect.propTypes = {
  onChange: func.isRequired,
  placeholder: string.isRequired,
  value: oneOfType([propTypesValue, arrayOf(propTypesValue), string]),
  onFocus: func,
  onMenuClose: func,
  onInputChange: func,
  setSearchTextForQuery: func,
  isDisabled: bool,
  required: bool,
  customIcon: node,
  error: string,
  // eslint-disable-next-line react/forbid-prop-types
  components: object,
  styleType: string,
  closeMenuOnSelect: bool,
  hideSelectedOptions: bool,
  isMulti: bool,
  isOutForm: bool,
  requiredWithOutError: bool,
  hasError: bool,
  withPortal: bool,
  isClearable: bool,
  width: string,
};

BaseSelect.defaultProps = {
  onFocus: () => {},
  onInputChange: () => {},
  onMenuClose: () => {},
  setSearchTextForQuery: () => {},
  components: {},
  withPortal: false,
};
