import React from 'react';
import * as R from 'ramda';
import { object, oneOfType, string, arrayOf } from 'prop-types';
import { useUpdateQueryParams } from '@poly/client-routing';
import { useOutSidebarContext } from '@poly/client-utils';
import { SidebarWidth } from '@poly/admin-ui';
import styled from 'styled-components';

import { SidebarIDs } from '../constants.js';
import { AssetSidebar } from './AssetSidebar.js';
import { LinkToSidebar } from '../../components/LinkToSidebar.js';
import { formatAssetTitle } from './assetSidebarUtils.js';
import { useClearPristineState } from '../useClearPristineOnSidebarMount.js';
import { AssetLifeExpectancyWarning } from './AssetLifeExpectancyWarning.js';

const FlexContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export function AssetLink({ _id, children, queryParams = {}, ...restProps }) {
  const linkParams = R.mergeDeepLeft(
    { query: { [SidebarIDs.asset]: _id } },
    queryParams,
  );

  const linkProps = { linkParams, ...restProps };

  return (
    <LinkToSidebar {...linkProps}>
      {children || formatAssetTitle(restProps)}
    </LinkToSidebar>
  );
}

AssetLink.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  queryParams: object,
  _id: string.isRequired,
  children: oneOfType([object, string, arrayOf(oneOfType([string, object]))]),
};

export function AssetLinkWithWarning(props) {
  return (
    <FlexContainer>
      <AssetLifeExpectancyWarning asset={props} />
      <AssetLink {...props} />
    </FlexContainer>
  );
}

export const useOpenAssetSidebar = () => {
  const { openOutSidebar } = useOutSidebarContext();

  return (assetId, skipSteady) =>
    openOutSidebar(
      {
        alwaysFirst: true,
        width: SidebarWidth,
        id: SidebarIDs.asset,
        content: assetId ? <AssetSidebar assetId={assetId} /> : null,
      },
      skipSteady,
    );
};

export const useNavigateToAssetSidebar = () => {
  const updateQueryParams = useUpdateQueryParams();
  const clearPristine = useClearPristineState();

  return (assetId) => {
    clearPristine();
    updateQueryParams({
      [SidebarIDs.asset]: assetId,
      [SidebarIDs.property]: undefined,
    });
  };
};
