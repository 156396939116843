import styled from 'styled-components';
import React from 'react';
import { TextButton, IconButton, defaultTheme, Select } from '@poly/admin-book';
import { func, arrayOf, string, shape } from 'prop-types';

import {
  FlexContainer,
  FlexCenterAlign,
} from '../../../../components/FlexContainer.js';

const {
  colors: { accent1Lighter1 },
} = defaultTheme;

const AddBtn = styled(TextButton)`
  margin-left: 10px;
  padding-top: 8px;
`;

const SelectedItem = styled.div`
  width: 100%;
  padding: 10px 0;
  font-weight: 500;
  font-size: 14px;
  color: #3e4c5b;
`;

export function ItemsSelectComp({
  value: selectedItems,
  onItemToAddChange,
  itemToAdd,
  availableOptions,
  onAddNewItem,
  onRemoveItem,
  optionLabelByKey,
  className,
  ...fieldProps
}) {
  const selectProps = {
    options: availableOptions,
    value: itemToAdd,
    onChange: onItemToAddChange,
    ...fieldProps,
  };

  return (
    <div className={className}>
      <FlexContainer>
        <Select {...selectProps} />
        <AddBtn onClick={onAddNewItem}>Add</AddBtn>
      </FlexContainer>
      {selectedItems.map((item) => (
        <FlexCenterAlign key={item}>
          <SelectedItem>{optionLabelByKey(item)}</SelectedItem>
          <IconButton
            onClick={() => onRemoveItem(item)}
            name="close"
            size={8}
            initialColor={accent1Lighter1}
            hoverColor={accent1Lighter1}
          />
        </FlexCenterAlign>
      ))}
    </div>
  );
}

ItemsSelectComp.propTypes = {
  itemToAdd: string.isRequired,
  availableOptions: arrayOf(shape({})).isRequired,
  value: arrayOf(string.isRequired).isRequired,
  onAddNewItem: func.isRequired,
  onItemToAddChange: func.isRequired,
  onRemoveItem: func.isRequired,
  optionLabelByKey: func.isRequired,
  className: string,
};
