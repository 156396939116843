import * as R from 'ramda';
import { pathOrNothingUI, getSupplierPhoneByType } from '@poly/client-utils';
import { nullToUndefinedDeep, propEqLegacy } from '@poly/utils';
import {
  supplierEmailsTypes,
  supplierPhoneTypes,
  supplierFinTermsUI,
  NOTHING_UI_STRING,
  collectionNames,
} from '@poly/constants';

import { supplierTaxClassesUI } from '../../../constants/suppliers.js';
import { getActiveOfficeHours } from '../../../utils/suppliers.js';

// getOpeningInvoicesTotal :: { searchInvoices: { hits: [Invoice] } } -> Float
export const getOpeningInvoicesTotal = R.compose(
  R.sum,
  R.map(R.prop('balance')),
  R.pathOr([], ['searchInvoices', 'hits']),
);

// pickByMapIfExists :: ([String], Object) -> String
const pickByMapIfExists = (path, map) =>
  R.compose(
    R.ifElse(R.isNil, R.always(NOTHING_UI_STRING), R.prop(R.__, map)),
    R.path(path),
  );

const multiEmailTypes = [
  supplierEmailsTypes.SECONDARY_SERVICE,
  supplierEmailsTypes.ACCOUNT,
];

// prepareEmails :: [SupplierEmail] -> [EmailByType]
// SupplierEmail = { type: SupplierEmailType, email: String }
// EmailByType = { [SupplierEmailType]: String }
const prepareEmails = (emails) =>
  R.compose(
    R.mergeAll,
    R.map((type) =>
      R.compose(
        R.objOf(type),
        pathOrNothingUI(['email']),
        R.find(propEqLegacy('type', type)),
      )(emails),
    ),
    R.reject(R.includes(R.__, multiEmailTypes)),
    R.values,
  )(supplierEmailsTypes);

// prepareSupplierEmailsForDisplay :: [SupplierEmail] -> EmailByType
const prepareSupplierEmailsForDisplay = R.compose(
  R.mergeAll,
  R.juxt([
    R.compose(
      R.objOf(supplierEmailsTypes.SECONDARY_SERVICE),
      R.pluck('email'),
      R.filter(propEqLegacy('type', supplierEmailsTypes.SECONDARY_SERVICE)),
    ),
    R.compose(
      R.objOf(supplierEmailsTypes.ACCOUNT),
      R.pluck('email'),
      R.filter(propEqLegacy('type', supplierEmailsTypes.ACCOUNT)),
    ),
    R.compose(
      prepareEmails,
      R.reject(R.propSatisfies(R.includes(R.__, multiEmailTypes), 'type')),
    ),
  ]),
);

// getSupplierPhoneNumberByType :: String -> Supplier -> String
const getSupplierPhoneNumberByType = (type) =>
  R.compose(
    getSupplierPhoneByType(type),
    R.defaultTo([]),
    R.path(['company', 'phones']),
  );

// prepareMasterSupplierDetails :: { MasterSupplier } -> Object
export const prepareMasterSupplierDetails = R.compose(
  R.applySpec({
    general: {
      type: R.prop('type'),
      name: pathOrNothingUI(['company', 'name']),
      dba: pathOrNothingUI(['company', 'dba']),
      address: R.path(['company', 'address']),
      addressTwo: R.path(['company', 'addressTwo']),
      service24: R.propOr(false, 'service_24_7'),
      officeHours: getActiveOfficeHours,
      phone: getSupplierPhoneNumberByType(supplierPhoneTypes.GENERAL),
      afterHoursPhone: getSupplierPhoneNumberByType(
        supplierPhoneTypes.AFTER_HOURS,
      ),
      fax: R.path(['company', 'fax']),
      emails: R.compose(
        prepareSupplierEmailsForDisplay,
        R.pathOr([], ['company', 'emails']),
      ),
      services: R.path(['company', 'services']),
      website: pathOrNothingUI(['company', 'website']),
      isWBE: R.pathOr(false, ['mwbe', 'isWBE']),
      minority: R.path(['mwbe', 'minority']),
      foodBuy: R.propOr({}, 'foodBuy'),
      isVeteran: R.prop('isVeteran'),
    },
    remarks: R.propOr('', 'remarks'),
    financial: {
      terms: pickByMapIfExists(['finance', 'terms'], supplierFinTermsUI),
      acceptsCreditCard: R.pathOr(false, ['finance', 'acceptsCreditCard']),
      is1099: R.pathOr(false, ['tax', 'is1099']),
      w9FileName: pathOrNothingUI(['tax', 'w9File', 'fileName']),
      w9FileUrl: R.path(['tax', 'w9File', 'url']),
      taxId: pathOrNothingUI(['tax', 'id']),
      socialSecurityNumber: pathOrNothingUI(['tax', 'socialSecurityNumber']),
      taxAddress1: R.pathOr({}, ['tax', 'address']),
      taxAddress2: pathOrNothingUI(['tax', 'addressTwo']),
      taxClass: pickByMapIfExists(
        ['tax', 'classification'],
        supplierTaxClassesUI,
      ),
      taxName: pathOrNothingUI(['tax', 'name']),
      cardNumber: R.prop('cardNumber'),
      openingInvoicesTotal: getOpeningInvoicesTotal,
      exempt: R.path(['tax', 'exempt']),
    },
    bank: {
      routingNumber: pathOrNothingUI(['bank', 'routingNumber']),
      accountingNumber: pathOrNothingUI(['bank', 'accountNumber']),
      remittanceEmails: R.pathOr([], ['bank', 'remittanceEmails']),
      payByACH: R.pathOr(false, ['bank', 'payByACH']),
    },

    remit: {
      name: pathOrNothingUI(['remit', 'name']),
      address: R.pathOr({}, ['remit', 'address']),
      addressTwo: R.path(['remit', 'addressTwo']),
    },

    documents: R.compose(
      R.mergeAll,
      R.juxt([
        R.compose(R.defaultTo({}), R.prop('documents')),
        R.applySpec({
          supplierId: R.prop('_id'),
          collection: R.always(collectionNames.masterSuppliers),
        }),
      ]),
    ),
    rates: R.compose(R.defaultTo({}), R.prop('rates')),
  }),
  nullToUndefinedDeep,
  R.propOr({}, 'masterSupplier'),
);
