import * as R from 'ramda';
import { undefinedAsNullDeep } from '@poly/client-utils';
import {
  getFileNameWithoutExt,
  removeFileExtension,
  removePropDeep,
  assocBy,
  ofArrayLegacy,
} from '@poly/utils';
import { getAssetReplacementCost } from '@poly/utils/src/getAssetReplacementCost.js';

import { prepareNewEquipmentToMutation } from '../add/useOnSubmitAddAssetForm.js';
import { selectSingleFileNameWithUpload } from '../../../../utils/files.js';

// matchWithOldFileName :: Asset -> FormData -> Boolean
const matchWithOldFileName = (asset) =>
  R.converge(R.equals, [
    R.compose(
      R.when(R.is(String), removeFileExtension),
      R.path(['photo', 'fileName']),
      R.always(asset),
    ),
    R.path(['photo', 0, 'fileName']),
  ]);

// isEmptyUpload :: FormData -> Boolean
const isEmptyUpload = R.compose(
  R.either(R.isNil, R.isEmpty),
  R.path(['photo', 'upload']),
);

// getFieldEditedByAsset :: (String, Asset) -> FormData -> UpdateAssetInput
const getFieldEditedByAsset = (fieldName, asset) =>
  R.when(
    R.both(
      R.propSatisfies(R.isNil, fieldName),
      R.compose(R.complement(R.isNil), R.prop(fieldName), R.always(asset)),
    ),
    R.assoc(fieldName, null),
  );

// prepareModelId :: { modelId: ID } -> { modelId: ID }
const prepareModelId = R.when(
  R.propSatisfies(R.isNil, 'modelId'),
  R.assoc('modelId', null),
);

// prepareFormDataToMutation :: Asset -> FormData -> UpdateAssetInput
export const prepareFormDataToMutation = (asset) =>
  R.compose(
    prepareNewEquipmentToMutation,
    R.omit(['_id', 'type', 'isEdit', 'clientId', 'showClient']),
    R.cond([
      [R.propSatisfies(R.isEmpty, 'photo'), R.assoc('photo', null)],
      [R.both(isEmptyUpload, matchWithOldFileName(asset)), R.dissoc('photo')],
      [R.T, R.over(R.lensProp('photo'), selectSingleFileNameWithUpload)],
    ]),
    getFieldEditedByAsset('name', asset),
    getFieldEditedByAsset('qrCodeId', asset),
    getFieldEditedByAsset('serial', asset),
    getFieldEditedByAsset('warrantyEnd', asset),
    getFieldEditedByAsset('description', asset),
    getFieldEditedByAsset('newModelName', asset),
    getFieldEditedByAsset('equipmentType', asset),
    getFieldEditedByAsset('purchasePrice', asset),
    getFieldEditedByAsset('commissioningDate', asset),
    R.when(
      R.propSatisfies(R.isNil, 'defaultSupplierId'),
      R.assoc('defaultSupplierId', null),
    ),
    prepareModelId,
    R.reject(R.isNil),
    undefinedAsNullDeep,
  );

// prepareAssetFormInitialValues :: Asset -> FormData
export const prepareAssetFormInitialValues = R.compose(
  R.omit(['uploadPhotoUrl']),
  R.over(
    R.lensProp('photo'),
    R.ifElse(
      R.isNil,
      R.always([]),
      R.compose(
        ofArrayLegacy,
        R.applySpec({
          _id: R.prop('fileName'),
          fileName: R.pipe(R.prop('fileName'), getFileNameWithoutExt),
          url: R.prop('url'),
        }),
      ),
    ),
  ),
  R.omit(['client', 'property', 'suppliers', 'displayName', 'defaultSupplier']),
  assocBy(
    'isReplacementCostChanged',
    R.ifElse(
      R.prop('replacementCost'),
      R.converge(R.complement(R.equals), [
        R.compose(getAssetReplacementCost, R.omit(['replacementCost'])),
        R.prop('replacementCost'),
      ]),
      R.F,
    ),
  ),
  R.when(R.prop('newManufacturerName'), R.assoc('isNewManufacturer', true)),
  R.when(R.prop('newModelName'), R.assoc('isNewModel', true)),
  assocBy('modelId', R.path(['modelDoc', '_id'])),
  assocBy('manufacturerId', R.path(['manufacturerDoc', '_id'])),
  assocBy('typeId', R.path(['type', '_id'])),
  assocBy('clientId', R.path(['client', '_id'])),
  assocBy('propertyId', R.path(['property', '_id'])),
  assocBy('defaultSupplierId', R.path(['defaultSupplier', '_id'])),
  removePropDeep('__typename'),
);
