import { gql } from '@apollo/client';
import { commonAddressFields } from '@poly/admin-ui';

export const supplierDocumentsFields = gql`
  fragment supplierDocumentsFields on Documents {
    exempt
    wcExp
    glExp
    wcFile {
      fileName
      fileType
      url
    }

    glFile {
      fileName
      fileType
      url
    }

    scaFile {
      fileName
      fileType
      url
    }

    sqqFile {
      fileName
      fileType
      url
    }

    subcontractorAgreement {
      title
      contactName
      ip
      downloadedAt
      date
      version
    }

    mbeCertificationFile {
      fileName
      fileType
      url
    }
    wbeCertificationFile {
      fileName
      fileType
      url
    }
    veteranCertificationFile {
      fileName
      fileType
      url
    }
    exemptDocumentsRequest
  }
`;

export const supplierAccountFields = gql`
  fragment supplierAccountFields on Supplier {
    _id
    type
    status
    remarks
    isVeteran
    isPrivate
    service_24_7
    officeHours {
      day
      isActive
      startTime
      endTime
    }
    misc {
      source
    }
    masterSupplier {
      _id
      company {
        name
      }
    }
    mwbe {
      minority
      isWBE
    }
    foodBuy {
      isEnabled
      sapVendorNumber
      vendorType
      vaPercentage
      termSet
      vaEmail
    }
    finance {
      acceptsCreditCard
      terms
    }
    tax {
      exempt
      is1099
      id
      name
      classification
      address {
        ...commonAddressFields
      }
      addressTwo
      socialSecurityNumber
      w9File {
        url
        fileName
      }
      exempt
    }
    rates {
      hourly
      overTime
      trip
      weekend
    }
    bank {
      routingNumber
      accountNumber
      remittanceEmails
      payByACH
    }

    documents {
      ...supplierDocumentsFields
    }

    company {
      name
      dba
      fax
      addressTwo
      website
      address {
        ...commonAddressFields
      }
      phones {
        phone
        type
        isPrimary
        legacyFormat
      }
      emails {
        email
        type
      }
      services {
        _id
        name
      }
    }
    cardNumber
    openingInvoicesTotal
    remit {
      name
      address {
        ...commonAddressFields
      }
      addressTwo
    }
  }
  ${commonAddressFields}
  ${supplierDocumentsFields}
`;
